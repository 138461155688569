var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{ref:"el",class:{
           'auto-col-column':_vm.mode == 'column',
           ['auto-col-justify-'+_vm.justify]: _vm.justify != 'start',
           ['auto-col-align-items-'+_vm.items]: _vm.items != 'start',
           ['auto-col-'+_vm.wrap]: _vm.wrap != 'nowrap' && _vm.wrap !== true,
		   ['auto-col-wrap']:_vm.wrap === true || _vm.wrap == 'wrap',

        },style:(_vm.style)},[_vm._t("default",null,{"el":_vm.el})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }