<template>
    <div 
        v-if="show"
	    :style="style" 
        ref="el"
        :class="{
           'auto-col-column':mode == 'column',
           ['auto-col-justify-'+justify]: justify != 'start',
           ['auto-col-align-items-'+items]: items != 'start',
           ['auto-col-'+wrap]: wrap != 'nowrap' && wrap !== true,
		   ['auto-col-wrap']:wrap === true || wrap == 'wrap',

        }"
    >
        <slot :el="el"></slot>
    </div>
</template>
<script>
    export default {
        name: 'auto-col',
        components: {},
        props: {
            size:[String,Number],
            min:[String,Number],
            max:[String,Number],
            mode:{
                type:String,
                default:"row",
            },
            justify:{
                type:String,
                default:"start",
            },
            items:{
                type:String,
                default:"start",
            },
            wrap:{
				type:[String, Boolean],
				default:"nowrap",
            },        
        },
        inject: ['getMode'],
        data() {
            return {
                el:null,
            }
        },
        computed: { //计算属性
            style(){
                let type = (this.getMode && this.getMode() == "column") ? "Height" : "Width";
                let {
                    size,
                    min,
                    max,
                } = this;
                return {
                    flex:size || "none",
                    ["min"+type]:min + (/^\d+\.?\d+$/.test(min) ? "px" : ""),
                    ["max"+type]:max + (/^\d+\.?\d+$/.test(max) ? "px" : ""),
                    // overflow: size == "auto" ? "100%" : undefined,
                    overflow: size == "auto" ? "visible" : undefined,
                };
            },
            show(){
                return /^vue-component-\d+-auto-row$/.test(this.$parent.$vnode.tag);
            }
        },
        watch: {  //监听器

        },
        methods: {
           
        },
		beforeCreate(){ //在实例初始化之后被调用
			
		},
		created(){ //在实例创建完成后被立即调用

		},
		beforeMount(){ //在挂载开始之前被调用
			
		},
		mounted(){ //挂载到实例上去之后调用
            this.el = this.$refs.el;
            this.$emit("load");
		},
		beforeUpdate(){ //数据更新时调用，发生在虚拟 DOM 打补丁之前
			
		},
		updated(){ //由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子
			
		},
		beforeDestroy(){ //实例销毁之前调用。在这一步，实例仍然完全可用
			
		},
		destroyed(){ //	Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁
			
		},
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<style scoped>
.auto-col-column,
.auto-col-justify-start,
.auto-col-justify-end,
.auto-col-justify-center,
.auto-col-justify-between,
.auto-col-justify-around,
.auto-col-align-items-start,
.auto-col-align-items-end,
.auto-col-align-items-center,
.auto-col-align-items-baseline,
.auto-col-align-items-stretch,
.auto-col-wrap,
.auto-col-wrap-reverse{
	display:flex;
	flex-wrap:nowrap;
}
.auto-col-column{
    flex-direction: column;
}

.auto-col-justify-start{
    justify-content: flex-start;
}
.auto-col-justify-end{
    justify-content: flex-end;
}
.auto-col-justify-center{
    justify-content: center;
}
.auto-col-justify-between{
    justify-content: space-between;
}
.auto-col-justify-around{
    justify-content: space-around;
}

.auto-col-align-items-start{
    align-items: flex-start;
}
.auto-col-align-items-end{
    align-items: flex-end;
}
.auto-col-align-items-center{
    align-items: center;
}
.auto-col-align-items-baseline{
    align-items: baseline;
}
.auto-col-align-items-stretch{
    align-items: stretch;
}

.auto-col-wrap{
    flex-wrap:wrap;
}
.auto-col-wrap-reverse{
    flex-wrap:wrap-reverse;
}
</style>
